// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap');
html,
body {
  height: 100%;
}

.react {
  html,
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  span,
  label {
    color: $text-default !important;
  }
  height: 100%;
  p span,
  p {
    font-family: Oswald;
    line-height: 1.6;
    color: $text-default-light;
  }

  h4 {
    font-family: PasseroOne;
    font-size: 1.625rem;
  }

  h2 {
    color: #fff5af !important;
    text-align: center;
  }

  h3 {
    font-family: PasseroOne;
    font-size: 2.25rem;
  }

  h1 {
    font-family: PasseroOne;
  }

  /* Select Style */
  select {
    background-image: url('../../../web/src/assets/images/buttonBk.png');
    background-size: 100% 100%;
    background-color: transparent !important;
    border: none !important;
    height: 30px !important;
    padding: 3px 15px !important;
    color: #120c05 !important;
    font-size: 16px !important;
    outline: none !important;
  }

  select:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  /* Button Style */
  button {
    background-image: url('../../../web/src/assets/images/buttonBk.png');
    background-size: 100% 100%;
    background-color: transparent !important;
    border: none !important;
    height: 30px !important;
    padding: 3px 15px !important;
    color: #120c05 !important;
    font-size: 16px !important;
    outline: none !important;
  }
  button.bg-none {
    background-image: none;
  }

  button:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  button.btn-md {
    min-width: 120px;
  }
  button.btn-sm {
    min-width: 60px;
  }

  .btn.disabled,
  .btn:disabled {
    opacity: 0.4;
  }

  button.dropdown-toggle {
    background-image: none !important;
    color: $text-default !important;
  }

  button.dropdown-toggle:hover {
    box-shadow: none !important;
  }

  button.dropdown-toggle::after {
    margin-left: 0.5em !important;
    margin-top: 0.5em !important;
    vertical-align: 0.1em;
  }

  button:hover {
    transition: 0.3s;
    -webkit-filter: drop-shadow(0 0 0.75rem rgba(255, 255, 255, 0.4));
    filter: drop-shadow(0 0 0.75rem rgba(255, 255, 255, 0.4));
  }

  p {
    font-size: 1rem;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(253, 243, 173, 0.3) !important;
    opacity: 1; /* Firefox */
  }

  .f-oswald {
    font-family: Oswald !important;
  }

  .text-primary-dark {
    color: $text-default-dark;
    opacity: 0.3;
  }

  .text-primary-light {
    color: $text-default-light !important;
  }

  .text-yellow {
    color: #ffd800 !important;
    font-size: 28px !important;
  }

  .text-coupon {
    opacity: 0.5;
    text-decoration: line-through;
  }

  a:hover {
    transition: 0.3s;
    color: $text-default-light !important;
    text-decoration: none !important;
  }

  .form-group label {
    font-size: 20px;
  }

  .social-icons {
    a {
      text-decoration: none;
      font-size: 18px;
    }
  }

  .error-text {
    font-family: PasseroOne;
    font-size: 14;
    color: red !important;
  }
  .green-text {
    color: rgb(9, 213, 2) !important;
  }

  .input-wrapper {
    input {
      background-image: url('../../../web/src/assets/images/searchInputBg.png');
      height: 35px;
    }
  }

  input {
    background-color: transparent !important;
    background-image: url('../../../web/src/assets/images/inputBg.png');
    background-size: 100% 100%;
    height: 40px;
    border-radius: 0 !important;
    border: none !important;
    font-size: 20px !important;
    color: $text-default-light !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .w-90 {
    width: 90%;
  }

  .w-80 {
    width: 80%;
  }

  .w-70 {
    width: 70%;
  }

  .w-60 {
    width: 60%;
  }

  .w-50 {
    width: 50%;
  }

  .w-40 {
    width: 40%;
  }

  .w-30 {
    width: 30%;
  }

  .w-20 {
    width: 20% !important;
  }

  .w-15 {
    width: 15%;
  }

  .w-10 {
    width: 10%;
  }

  .w-5 {
    width: 5%;
  }

  .Toastify__toast {
    background-color: transparent;
    color: #120c05 !important;
    font-weight: bold !important;
    background-image: url(../../../web/src/assets/images/btn-right.png);
  }
  .Toastify__toast-icon {
    background: #fff;
    border-radius: 50%;
    margin-left: 20px;
  }
  .Toastify__progress-bar {
    height: 10px;
    left: 25px !important;
  }
}
