.vesting-bg {
  background-image: url('../../assets/img/VestingBg.png');

  /* Full height */
  height: 100%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.box {
  background-image: url('../../assets/img/VestingModal.png');
  background-size: 100% 100%;
  padding: 80px 43px;
  width: 672px;
  height: 590px;

  .box__header {
    font-size: 1.5rem;
    color: rgb(239, 194, 85);
    line-height: 1.2;
    border-bottom: 1px white solid;
    min-width: 585px;
    text-align: center;
    margin-bottom: 10px;
  }
  .box__body--connected {
    flex: 1;
    justify-content: space-around;
  }
  .box__text {
    font-size: 1.125rem;
    color: rgb(239, 194, 85);
    line-height: 1.2;
  }
  .box__text--underlay {
    font-size: 1.125rem;
    background-image: url('../../assets/img/VestingUnderlay.png');
    background-size: 100% 100%;
    color: rgb(255, 245, 175);
    text-align: center;
  }
  .box__button {
    width: 235px;
    height: 35px !important;
    margin-top: 20px;
    font-size: 1.2rem !important;
  }
  .box__underline {
    border-bottom: 1px white solid;
    width: 100%;
    margin-top: 20px;
  }

  .box__text--oswald {
    font-family: Oswald, sans-serif;
  }
  .box__text--passeroone {
    font-family: PasseroOne, sans-serif;
  }
}
